import gsap, {Power2, Power3} from 'gsap'
import DrawSVGPlugin from "gsap/DrawSVGPlugin"
gsap.registerPlugin(DrawSVGPlugin)

export default {

    data() {
        return {
            slider: null,
            tl: null,
            // slider2: null,
            // tl2: null
        }
    },
    methods: {
        initSlider() {
            this.$slider = $("#slider");
            
            this.$slider.slider({
                range: false,
                min: 0,
                max: 100,
                step: 0.02,
                value: 0,
                slide: (event, ui) => {
                    this.tl.progress(ui.value / 100).pause();
                },
                stop: () => {
                    this.tl.play();
                }
            });
        },
        // initSlider2() {
        //     this.$slider2 = $("#slider2");
            
        //     this.$slider2.slider2({
        //         range: false,
        //         min: 0,
        //         max: 100,
        //         step: 0.02,
        //         value: 0,
        //         slide: (event, ui) => {
        //             this.tl2.progress(ui.value / 100).pause();
        //         },
        //         stop: () => {
        //             this.tl2.play();
        //         }
        //     });
        // },
        updateSlider() {
            this.$slider.slider("value", this.tl.progress() * 100);
        },
        initTimeline() {

            var vw = $(window).width();
            var vh = $(window).height();
            var speed = 0.85;

            this.tl = gsap.timeline({
                onUpdate: this.updateSlider,
                paused: true
            });
            this.tl.timeScale(2.7);

            // Mondrian
            this.tl.fromTo($('.get-to-know__right-image').find('.whoWeAreTop'), speed,
                { x: -0, opacity: 0, force3D: true },
                { x: 0, opacity: 1, ease: Power2.easeInOut },
                0);

            this.tl.add(this.getWhoWeAreTopTl(), 0.2);

        },
        getWhoWeAreTopTl() {
            var whoWeAreTl = gsap.timeline({delay:2});
            let stagger = 0.3;

            whoWeAreTl.fromTo($('.top-line'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);

            whoWeAreTl.fromTo($('.middle-right'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            whoWeAreTl.fromTo($('.middle-left'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            whoWeAreTl.fromTo($('.left-arc'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);

            whoWeAreTl.fromTo($('.left-line'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1.2);
            whoWeAreTl.fromTo($('.top-arc'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1.4);
            

            whoWeAreTl.fromTo($('.bottom-line'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, .8);
            whoWeAreTl.fromTo($('.bottom-arc'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2);

            whoWeAreTl.fromTo($('.circle-1'), 1, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 3);
            whoWeAreTl.fromTo($('.circle-2'), 1, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 3.5);
            whoWeAreTl.fromTo($('.circle-3'), 1, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 4);

            whoWeAreTl.fromTo($('.yellow-fill'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=4");
            whoWeAreTl.fromTo($('.whoWeAreBgImage'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=4");

            // this.tl.from($('.whoWeAreBgImage'), 1.3, { opacity: 0, scale: 1.05, transformOrigin: '50% 50%', ease: Power2.easeInOut }, "-=1.7");

            return whoWeAreTl;
        }
    }

}
