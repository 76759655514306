<template>
    <div class="game-image-link" :class="'game-grid-' + num">
        <div class="inner-wrapper" v-scroll-reveal>
            <img :src="game.image" :alt="game.title + ' preview image'" class="game-image" @click="onMobileImageClick">
            <a class="game-description" :href="game.url" target="_blank" :style="{ 'cursor': game.url ? 'pointer' : 'default'}" @click="onClick($event, game.url)">
                <p class="date">{{ game.date }}</p>
                <div class="middle">
                    <div class="game-title" v-html="game.title"></div>
                    <p class="game-subtitle">{{ game.subtitle }}</p>
                </div>
                <p class="link-type">{{ game.linkType }}</p>
            </a>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import gsap from 'gsap'


    export default {
        props: ['game', 'num'],
        methods: {
            onClick(e, link) {
                if (!link) {
                    e.preventDefault();
                }
            },
            onMobileImageClick(e) {

                // on mobile, show hover state on first click
                // second click launches website link

                if ($('html').hasClass('ua-mobile')) {

                    // reset all other thumbs to initial state
                    $('.inner-wrapper').removeClass('hover');
                    gsap.to('.game-image', 0.3, {
                        autoAlpha: 1
                    });

                    var $image = $(e.currentTarget);

                    Vue.nextTick(()=> {
                        gsap.to($image, 0.3, {
                            autoAlpha: 0
                        });
                        $image.parent().addClass('hover');
                    });
                }
            }
        },
    }
</script>